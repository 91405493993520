import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 'terrific'};

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCkWQ4_GbRT_GpXs5clfayvbB0bbnTH5ss',
    authDomain: 'terrific-devs.firebaseapp.com',
    projectId: 'terrific-devs',
    storageBucket: 'terrific-devs.appspot.com',
    messagingSenderId: '278205526112',
    appId: '1:278205526112:web:99b0a978ad606236aad305',
    measurementId: 'G-6ZTX9Y7MH5',
  },
  useEmulators: false,
  maxUplodedImageSize: 5000001,
  allowImageExt: '.jpg,.png,.jpeg',
  featureFlags: {longPolling: true},
  client,
};
