import {inject, Injectable} from '@angular/core';
import {AngularFirestore, QuerySnapshot} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Timestamp, arrayUnion} from '@angular/fire/firestore';
import {v4 as uuidv4} from 'uuid';
import {
  Observable,
  Subject,
  first,
  firstValueFrom,
  from,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import {DbCurrencyModel} from '../../../../shared/db-models/payments';
import {DbSessionModel} from '../../../../shared/db-models/session';
import {DbStoreModel, StoreAllowedDomain} from '../../../../shared/db-models/store';
import {CSV_Strategy} from '../../../../shared/ecommerce-platform-integration/csv-strategies';
import {IECommercePlatformIntegration} from '../../../../shared/ecommerce-platform-integration/ecommerce-platform-integration';
import {AngularFireStorage} from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  private storage = inject(AngularFireStorage);

  addProducts(storeId: string, strategy: CSV_Strategy, products: unknown[]) {
    return from(import('../../../../shared/utilities/integration')).pipe(
      switchMap((m) => {
        const {convertProductsRowsToTerrificFormat} = m;
        return convertProductsRowsToTerrificFormat(strategy(products, storeId));
      }),
      switchMap(({terrificProducts, terrificVariants}) => {
        const batch = this.firestore.firestore.batch();

        terrificProducts.forEach((product) =>
          batch.set(this.firestore.doc(`products/${product.id}`).ref, product)
        );

        terrificVariants.forEach((variant) =>
          batch.set(
            this.firestore.doc(`products/${variant.productId}/productVariants/${variant.id}`).ref,
            variant
          )
        );

        return from(batch.commit()).pipe(map(() => terrificProducts.length));
      })
    );
  }
  constructor(private firestore: AngularFirestore, private fns: AngularFireFunctions) {}

  // region Stores

  public getAllStores(): Observable<QuerySnapshot<DbStoreModel>> {
    return this.firestore.collection<DbStoreModel>(`stores`).get();
  }

  public getStoreById(id: string) {
    return this.firestore.doc<DbStoreModel>(`stores/${id}`).get();
  }

  public getAllowedDomains(storeId: string) {
    return this.firestore
      .collection<StoreAllowedDomain>(`stores/${storeId}/allowedDomains`)
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map((doc) => ({
            ...doc.data(), // Spread the document's fields
            id: doc.id, // Include the document ID
          }))
        )
      );
  }

  public getSessions() {
    const stores = this.getAllStores().pipe(
      map((stores) => stores.docs.map((store) => store.data()))
    );
    return this.firestore
      .collection<DbSessionModel>(`sessions`)
      .get()
      .pipe(
        map((query) => query.docs),
        map((docs) => docs.map((doc) => doc.data())),
        withLatestFrom(stores),
        map(([sessions, stores]) =>
          sessions.map((session) => ({
            ...session,
            storeName: stores.find((store) => store.id === session.storeId)?.name,
          }))
        ),
        map((sessions) =>
          sessions.map((session) => ({
            id: session.id,
            name: `${session.storeName} - ${session.hostName} - ${session.startTime
              .toDate()
              .toLocaleString()}`,
          }))
        ),
        first()
      );
  }

  public setStoreWriteOnlyDoc(storeId: string, docName: string, data: unknown) {
    console.debug('storeId: string, docName: string, data: unknown', storeId, docName, data);
    const doc = this.firestore.doc<unknown>(`stores/${storeId}/write-only/${docName}`);
    if (!data) return from(doc.delete().catch((err) => console.error(err)));
    return from(doc.set(data, {merge: true}));
  }

  public getStoreReadOnlyDoc<T>(storeId: string, docName: string) {
    console.debug('storeId: string, docName: string, data: unknown', storeId, docName);
    return this.firestore
      .doc<T>(`stores/${storeId}/write-only/${docName}`)
      .get()
      .pipe(map((doc) => (doc.exists ? doc.data() : undefined)));
  }

  public getIdForNewStore() {
    return this.firestore.collection(`stores`).doc().ref.id;
  }

  public getStoreIdsThatHaveIntegration(): Observable<Array<string>> {
    const collectionRef = this.firestore.collection('ecommercePlatformIntegrations');
    return from(collectionRef.get()).pipe(
      map((querySnapshot) => {
        const allStoreIds = new Set<string>();
        querySnapshot.forEach((doc) => {
          const terrificStoreIds = (<IECommercePlatformIntegration>doc.data())
            .terrificStoreIds as string[];
          if (terrificStoreIds) {
            terrificStoreIds.forEach((id) => allStoreIds.add(id));
          }
        });
        return Array.from(allStoreIds);
      })
    );
  }

  public async checkIfStoreHasLinkedIntegration(storeId: string): Promise<boolean> {
    const querySnapshot = await this.firestore
      .collection('ecommercePlatformIntegrations')
      .ref.where('terrificStoreIds', 'array-contains', storeId)
      .limit(1)
      .get();
    return !querySnapshot.empty;
  }

  public getShopifyStoreIdsThatHaveIntegration(): Observable<Array<string>> {
    const collectionRef = this.firestore
      .collection('ecommercePlatformIntegrations')
      .ref.where('type', '==', 'anonymous-shopify');
    return from(collectionRef.get()).pipe(
      map((querySnapshot) => {
        const allStoreIds = new Set<string>();
        querySnapshot.forEach((doc) => {
          const terrificStoreIds = (<IECommercePlatformIntegration>doc.data())
            .terrificStoreIds as string[];
          if (terrificStoreIds) {
            terrificStoreIds.forEach((id) => allStoreIds.add(id));
          }
        });
        return Array.from(allStoreIds);
      })
    );
  }

  /**
   * Creates or updates a store and returns it's id
   * @param store The store object
   */
  public updateStore(store: DbStoreModel) {
    return from(this.firestore.doc(`stores/${store.id}`).set(store, {merge: true})).pipe(
      switchMap(async () => {
        if (!store.externalUrl) return null;
        const hostName = new URL(store.externalUrl).hostname;
        const hasShopifyHandle = await fetch(`https://${hostName}/products.json?limit=1`)
          .then(() => true)
          .catch(() => false);
        if (!hasShopifyHandle) return null;
        const data: IECommercePlatformIntegration = {
          createDate: Timestamp.now(),
          storeUrl: hostName,
          terrificStoreIds: arrayUnion(store.id) as unknown as string[],
          type: 'anonymous-shopify',
          id: `anonymous-shopify-${hostName}`,
        };
        return this.firestore
          .doc(`ecommercePlatformIntegrations/${data.id}`)
          .set(data, {merge: true});
      }),
      map(() => store.id)
    );
  }

  public updateAllowedDomains(storeId: string, allowedDomains: StoreAllowedDomain[] = []) {
    const collectionRef = this.firestore.collection(`stores/${storeId}/allowedDomains`);
    const firestoreBatch = this.firestore.firestore.batch();
    return from(collectionRef.get()).pipe(
      switchMap((snapshot) => {
        snapshot.docs.forEach((doc) => firestoreBatch.delete(doc.ref));
        return firestoreBatch.commit();
      }),
      switchMap(() => {
        const newBatch = this.firestore.firestore.batch();
        allowedDomains.forEach((domain) => {
          const docId = domain.id || uuidv4();
          const docRef = collectionRef.doc(docId).ref;
          newBatch.set(docRef, {...domain, id: docId}, {merge: true});
        });
        return newBatch.commit();
      }),
      map(() => storeId)
    );
  }

  public syncIntegrationData(storeId: string): Observable<void> {
    return this.fns.httpsCallable('triggerIntegrationDataSync')({storeId});
  }

  // endregion

  // region Currencies

  public getAllCurrencies() {
    return this.firestore.collection<DbCurrencyModel>(`currencies`).get();
  }

  public getCurrencyById(id: string) {
    return this.firestore.doc<DbCurrencyModel>(`currencies/${id}`).get();
  }

  /**
   * Creates or updates a currency and returns it's id
   * @param currency The currency object
   */
  public updateCurrency(currency: DbCurrencyModel): Observable<string> {
    const subject = new Subject<string>();

    currency = Object.assign({}, currency);

    if (currency.id) {
      this.firestore
        .doc(`currencies/${currency.id}`)
        .update(currency)
        .then(() => {
          subject.next(currency.id ?? '');
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => {
          subject.complete();
        });
    } else {
      const doc = this.firestore.collection(`currencies`).doc();
      currency.id = doc.ref.id;
      doc
        .set(currency)
        .then(() => {
          subject.next(currency.id);
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => {
          subject.complete();
        });
    }

    return subject.asObservable();
  }

  // endregion

  public syncFromProductsFeed({storeId, feedUrl}: {storeId: string; feedUrl: string}) {
    return this.fns.httpsCallable('syncFromProductsFeed')({feedUrl, storeId});
  }
  public getFileFromAssets(imagePath: string, fileName: string, type: string): Observable<File> {
    return from(
      fetch(imagePath)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          return new File([blob], fileName, {type});
        })
    );
  }
  /**
   * Upload a store host image
   *
   * @param storeId The store id
   * @param file The host image
   */
  public uploadStoreHostImageFile(storeId: string, file: File): Observable<string> {
    const path = `stores/${storeId}/logos/${uuidv4()}.png`;
    return this.uploadFileToStorage(path, file);
  }

  /**
   * Upload a store host image
   *
   * @param storeId The store id
   * @param file The host image
   */
  public uploadStoreBannerImageFile(storeId: string, file: File): Observable<string> {
    const path = `stores/${storeId}/banners/${uuidv4()}.jpg`;
    return this.uploadFileToStorage(path, file);
  }

  private uploadFileToStorage(filePath: string, file: File): Observable<string> {
    const subject = new Subject<string>();
    const response = subject.asObservable();

    this.storage
      .upload(filePath, file)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then((downloadUrl: string) => {
            subject.next(downloadUrl);
          })
          .catch((err) => {
            subject.error(err);
          })
          .finally(() => {
            subject.complete();
          });
      })
      .catch((err) => {
        subject.error(err);
        subject.complete();
      });

    return response;
  }

  public async getDuplicatesStoresByProp(
    prop: keyof DbStoreModel,
    store: DbStoreModel
  ): Promise<DbStoreModel[]> {
    const value = store[prop];
    const storeId = store.id;
    const snapshot = await firstValueFrom(
      this.firestore.collection<DbStoreModel>(`stores`, (ref) => ref.where(prop, '==', value)).get()
    );
    if (snapshot.empty) {
      return [];
    }
    const duplicates: DbStoreModel[] = [];
    snapshot.forEach((doc) => {
      const store = doc.data() as DbStoreModel;
      if (store.id !== storeId) {
        duplicates.push(doc.data() as DbStoreModel);
      }
    });
    return duplicates;
  }
}
